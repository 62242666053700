
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({})
export default class SsoUserPicker extends Mixins(SimpleInputMixin) {
  @Prop({ type: Array }) rules!: ((v: any) => boolean | string)[];

  isLoading = false;
  items: any[] = [];
  search = "";
  timeoutId: number | undefined;

  @Watch("search")
  onSearchChange(newSearch: string, oldSearch: string) {
    if (newSearch !== oldSearch) {
      this.searchItems();
    }
  }

  async searchItems() {
    clearTimeout(this.timeoutId);
    this.timeoutId = window.setTimeout(async () => {
      if (!this.search || !this.isValidEmail(this.search)) {
        this.items = [];
        return;
      }
      this.isLoading = true;
      try {
        const res = await this.$http.get(
            this.$store.state.ssoUri + "api/search-user?email=" + this.search
        );

        const jsonData = JSON.stringify(res, null, 2);
        console.log("API Yanıtı JSON:", jsonData);
        this.handleApiResponse(jsonData);

      } catch (e) {
        this.handleApiError();
      } finally {
        this.isLoading = false;
      }
    }, 500);
  }

  handleApiResponse(rawData: any) {
    let data: any = rawData;
    if (typeof data === 'string') {
      try {
        data = JSON.parse(data);
      } catch (error) {
        console.error("JSON parse hatası:", error);
        this.handleApiError();
        return;
      }
    }
    if (data.data && typeof data.data === 'object') {
      data = data.data;
    }
    const { status, user } = data;
    switch (status) {
      case "registered":
        if (user) {
          this.items = [{
            id: user.id,
            status: "registered",
            name: user.name || user.first_name || "",
            last_name: user.last_name || "",
            mail: user.email || this.search,
          }];
        } else {
          console.error("Registered user data is missing");
          this.handleApiError();
        }
        break;
      case "incomplete_registration":
        if (user) {
          this.items = [{
            id: user.id,
            status: "incomplete_registration",
            name: user.name || user.first_name || "",
            last_name: user.last_name || "",
            mail: user.email || this.search,
          }];
        } else {
          console.error("Incomplete registration user data is missing");
          this.handleApiError();
        }
        break;
      case "not_found":
        this.items = [{
          id: null,
          status: "not_found",
          name: "Kayıtlı Değil",
          last_name: "",
          mail: this.search
        }];
        break;
      default:
        console.warn("Bilinmeyen yanıt durumu:", status);
        this.handleApiError();
    }

    console.log("Oluşturulan items:", this.items);
  }

  handleApiError() {
    this.items = [{
      id: null,
      status: "error",
      name: "Hata Oluştu",
      last_name: "",
      mail: this.search
    }];
    this.$toast.error("Kullanıcı arama sırasında bir hata oluştu.");
  }

  userInput() {
    console.log("userInput çağrıldı:", this.localValue);

    if (!this.localValue || typeof this.localValue !== 'object') {
      this.emitValue(null);
      this.emitUserData(this.search, null, "not_found", true);
      return;
    }

    const selectedItem = this.localValue as any;
    console.log("Seçilen item:", selectedItem);

    this.emitValue(selectedItem.id);

    switch (selectedItem.status) {
      case "not_found":
        this.emitUserData(selectedItem.mail, null, "not_found", true);
        this.$emit("sendRegistrationMail", true);
        break;

      case "incomplete_registration":
        this.emitUserData(selectedItem.mail, selectedItem.id, "incomplete_registration", false);
        break;

      case "registered":
        this.emitUserData(selectedItem.mail, selectedItem.id, "registered", false);
        if (!selectedItem.confirmed) {
          this.$emit("emailNotConfirmed", true);
        } else {
          this.$emit("sendActivationMail", true);
        }
        break;

      default:
        this.emitUserData(this.search, null, "not_found", true);
        break;
    }
  }

  emitValue(id: number | null) {
    this.$emit('input', id);
  }

  emitUserData(email: string, ssoUserId: number | null, status: string, isNonSSOUser: boolean) {
    if (status !== "incomplete_registration" && !email) {
      console.error("Invalid email in emitUserData");
      return;
    }
    console.log("Kullanıcı Verileri Gönderiliyor:", {
      email,
      ssoUserId,
      status,
      isNonSSOUser,
    });

    if (status !== "incomplete_registration") {
      this.$emit("email", email);
    }
    this.$emit("ssouserid", ssoUserId);
    this.$emit("userStatus", status);
    this.$emit("nonSSOUserSelected", isNonSSOUser);
  }

  getItemValue(item: any) {
    return item.id !== null ? item.id : item.mail;
  }

  isValidEmail(email: string): boolean {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }
}
