
import { Component, Mixins, Prop, Ref } from "vue-property-decorator";
import TcKimlikNumarasi from "@/components/inputs/TcKimlikNumarasi.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import CinsiyetPicker from "@/components/pickers/CinsiyetPicker.vue";
import EPosta from "@/components/inputs/EPosta.vue";
import VekilTipiPicker from "@/components/pickers/VekilTipiPicker.vue";
import PersonelTipiPicker from "@/components/pickers/PersonelTipiPicker.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import YabanciKimlikNumarasi from "@/components/inputs/YabanciKimlikNumarasi.vue";
import VergiNumarasi from "@/components/inputs/VergiNumarasi.vue";
import MersisNumarasi from "@/components/inputs/MersisNumarasi.vue";
import SsoUserPicker from "@/components/pickers/SsoUserPicker.vue";
import Dates from "@/components/inputs/Dates.vue";
import YargiBirimleriPicker from "@/components/pickers/YargiBirimleriPicker.vue";

@Component({
  components: {
    YargiBirimleriPicker,
    Dates,
    SsoUserPicker,
    YabanciKimlikNumarasi,
    Tutar,
    FormWrapper,
    PersonelTipiPicker,
    VekilTipiPicker,
    CinsiyetPicker,
    TcKimlikNumarasi,
    EPosta,
    VergiNumarasi,
    MersisNumarasi,
  },
})
export default class PersonelForm extends Mixins(ObjectInputMixin) {
  @Prop({}) buroTuru!: number | null;
  @Prop({}) vergiDairesi!: string | null;
  @Prop({}) vergiKimlik!: number | null;
  @Prop({}) mersisNo!: number | null;
  @Prop({}) action!: string;
  @Prop() hideButton!: any;
  @Ref("formWrapper") formWrapper!: FormWrapper;

  roleData = null;
  isNonSSOUser = false;
  userStatus: 'not_found' | 'incomplete_registration' | 'registered' | null = null;

  handleUserStatus(status: 'not_found' | 'incomplete_registration' | 'registered' | null) {
    this.userStatus = status;
    console.log('Kullanıcı Durumu Alındı:', this.userStatus);
    if (status === 'incomplete_registration') {
      this.$toast.info("Kayıt tamamlanmamıştır. Lütfen daha önce gönderilen kayıt mailinizi kontrol ediniz!");
    }
  }


  get alertMessage(): string {
    if (this.userStatus === 'not_found') {
      return "Eklenen kişinin kaydı bulunmamaktadır. Eklediğiniz zaman bildirim maile iletilecektir.";
    } else if (this.userStatus === 'incomplete_registration') {
      return "Kayıt tamamlanmamıştır. Lütfen daha önce gönderilen kayıt mailinizi kontrol ediniz!";
    }
    return "";
  }

  get personelTuruRules() {
    return this.localValue.role_id ? true : "Lütfen bu alanı doldurun.";
  }

  get notIdariYonetici(): boolean {
    return this.localValue.role_id === 3;
  }

  get isHideButton() {
    return this.hideButton || this.hideButton === "";
  }

  handleNonSSOUser(isNonSSOUser: boolean) {
    this.isNonSSOUser = isNonSSOUser;
    if (this.userStatus !== 'incomplete_registration') {
        this.userStatus = isNonSSOUser ? 'not_found' : 'registered';
      }
  }

  onClose() {
    this.$emit("onClose");
  }

  mounted() {
    this.localValue.vergi_no = this.vergiKimlik;
    this.localValue.vergi_dairesi = this.vergiDairesi;
    this.localValue.mersis_no = this.mersisNo;
  }

  save() {
    console.log("Kaydetmeden Önce Kullanıcı Durumu: ", this.userStatus);

    if (this.userStatus === "incomplete_registration") {
      this.$toast.error(
          "Kayıt tamamlanmamıştır. Lütfen daha önce gönderilen kayıt mailinizi kontrol ediniz!"
      );
      return;
    }

    this.formWrapper.onFly = true;

    if (this.isNonSSOUser) {
      this.localValue.sso_user_id = null;
    }

    if (!this.localValue.sso_user_id && !this.localValue.email) {
      this.$toast.error("Lütfen bir kullanıcı seçin.");
      this.formWrapper.onFly = false;
      return;
    }

    // İstediğiniz alanları tek tek payload'a ekliyorsunuz:
    const payload = {
      sso_user_id:    this.localValue.sso_user_id,
      email:          this.localValue.email,
      giris_tarihi:   this.localValue.giris_tarihi,
      cikis_tarihi:   this.localValue.cikis_tarihi,
      mersis_no:      this.localValue.mersis_no,
      role_id:        this.localValue.role_id,
      vergi_dairesi:  this.localValue.vergi_dairesi,
      vergi_no:       this.localValue.vergi_no,
      // vb. başka hangi alanları eklemek istiyorsanız buraya ekleyebilirsiniz
    };

    this.$http
        .get("/api/v1/personel")
        .then((response) => {
          const users = [...response.data];

          if (this.localValue.id) {
            this.$http.put(this.action, payload).then(() => {
              this.$emit("onSuccess");
            });
          } else {
            if (
                users.find(
                    (user) =>
                        user.sso_user_id == this.localValue.sso_user_id &&
                        this.localValue.sso_user_id !== null
                )
            ) {
              this.$toast.error("Kullanıcı zaten ekli.");
            } else {
              this.$http
                  .post(this.action, payload)
                  .then(() => {
                    this.$toast.info(
                        "İşlemin tamamlanması için eklenen kullanıcının eposta adresine gönderilen doğrulama linkine tıklaması gerekmektedir."
                    );
                    this.$emit("onSuccess");
                  })
                  .finally(() => {
                    this.formWrapper.onFly = false;
                  });
            }
          }
        })
        .finally(() => (this.formWrapper.onFly = false));
  }

  onPersonelTipiChange() {
    delete this.localValue.vekil_tipi;
    this.input();
  }
}
